<template>
  <div class="main-page">
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 xl:col-12 sm:col-12 xs:col-12 comp-grid">
            <div>
              <form ref="observer" tag="form" @submit.prevent="validateForm()" @reset="resetForm">
                <div>
                  <table class="p-datatable-table" style="width: 100%">
                    <tbody>
                      <tr v-for="(formData, row) in arrFormData" :key="row" class="input-row">
                        <div class="card nice-shadow-5">
                          <div class="grid">
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("Select Organisation") }} *
                              </div>

                              <div class="">
                                <api-data-source @loaded="(response) =>
                                  mapOptionField(
                                    response,
                                    'third_party_agency_id'
                                  )
                                  " api-path="components_data/third_party_agency_id_option_list">
                                  <template v-slot="req">
                                    <Dropdown class="w-full" :class="getErrorClass(
                                      row,
                                      'third_party_agency_id'
                                    )
                                      " :loading="req.loading" optionLabel="label" optionValue="value"
                                      ref="ctrlthird_party_agency_id" v-model="formData.third_party_agency_id"
                                      :options="req.response" :label="$t('third_party_agency')"
                                      :placeholder="$t('select')">
                                      <template #option="slotProps">
                                        <div class="flex align-items-center">
                                          <div class="ml-2" v-if="slotProps.option.image">
                                            <Avatar :image="$utils.setImgUrl(
                                              slotProps.option.image
                                            )
                                              " />
                                          </div>
                                          <div>
                                            <div>
                                              {{ slotProps.option.label }}
                                            </div>
                                            <div v-if="slotProps.option.caption" class="text-sm text-500">
                                              {{ slotProps.option.caption }}
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                    </Dropdown>
                                  </template>
                                </api-data-source>
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("funding_or_scholarship") }} *
                              </div>
                              <div class="">
                                <api-data-source @loaded="
                                  formData.third_party_agencies_grant = ''
                                  " :load-on-mount="false"
                                  :api-path="`components_data/third_party_agencies_grant_id_option_list?lookup_third_party_agency_id=${formData.third_party_agency_id}`">
                                  <template v-slot="req">
                                    <Dropdown class="w-full" :class="getErrorClass(
                                      row,
                                      'third_party_agencies_grant_id'
                                    )
                                      " :loading="req.loading" optionLabel="label"
                                      ref="ctrlthird_party_agencies_grant_id" v-model="formData.third_party_agencies_grant
                                        " :options="req.response" :label="$t('funding_or_scholarship')"
                                      :placeholder="$t('select')" @change="() => {
                                        formData.third_party_agencies_grant_id =
                                          formData.third_party_agencies_grant.value;

                                        formData.grant_additional_info =
                                          formData.third_party_agencies_grant.additional_form_definition;
                                      }
                                        ">
                                    </Dropdown>
                                  </template>
                                </api-data-source>
                              </div>
                            </div>
                            <div class="my-2 mx-3" v-if="formData.third_party_agencies_grant
                              .grant_description
                              ">
                              <span class="text-primary font-bold">Description:</span>
                              {{
                                formData.third_party_agencies_grant
                                  .grant_description
                              }}
                            </div>
                            <div class="my-2 mx-3" v-if="formData.third_party_agencies_grant
                                  .allocated_amount
                                ">
                              <span class="text-primary font-bold">Amount (GHS):</span>
                              {{
                                formData.third_party_agencies_grant
                                  .allocated_amount
                              }}
                            </div>
                            <div v-if="formData.third_party_agencies_grant.website_url
                                " class="col-12 md:col-12 px-2">
                              <Divider align="left" type="dashed" class="my-2 p-divider-bottom">
                                <a style="text-decoration: none" class="btn animate__shakeX text-sm text-blue-500"
                                  target="_blank" :href="formData.third_party_agencies_grant
                                    .website_url
                                    ">{{
    $t("click_here_for_more_information")
  }}</a>
                              </Divider>
                            </div>
                            <div v-if="formData.third_party_agencies_grant
                                  .additional_form_definition
                                " class="col-12 md:col-12">
                              <div class="mb-4 text-sm font-bold text-green-500">
                                {{
                                  $t(
                                    "kindly_provide_additional_information_for_selected_item"
                                  )
                                }}
                              </div>
                              <div class="grid">
                                <div v-for="(definition, index) in JSON.parse(
                                  formData.grant_additional_info
                                )" :key="index" class="col-12 md:col-6">
                                  <div class="">
                                    <div class="mb-2 font-bold text-sm">
                                      {{ definition.label }} *
                                    </div>
                                    <div v-if="definition.datatype == 'Radio'">
                                      <div class="field-radiobutton" v-for="option of definition.options" :key="option">
                                        <RadioButton :id="option" :name="'ctrladditional_form_definition' +
                                          index
                                          " :value="option" v-model="definition.value"
                                          :pattern="validationRegex(definition)" :required="true" @change="() => {
                                            let additional_definition =
                                              JSON.parse(
                                                formData.grant_additional_info
                                              );
                                            let pos = additional_definition
                                              .map((val) => val.label)
                                              .indexOf(definition.label);
                                            additional_definition[pos].value =
                                              definition.value;

                                            formData.grant_additional_info =
                                              JSON.stringify(
                                                additional_definition
                                              );
                                          }
                                            " />
                                        <label :for="option">{{ option }}
                                        </label>
                                      </div>
                                    </div>
                                    <div v-else-if="definition.datatype == 'Dropdown'
                                      ">
                                      <Dropdown class="w-full" :ref="'ctrladditional_form_definition' +
                                        index
                                        " v-model="definition.value" :options="definition.options"
                                        :placeholder="$t('select')" :pattern="validationRegex(definition)"
                                        :required="true" @change="() => {
                                          let additional_definition =
                                            JSON.parse(
                                              formData.grant_additional_info
                                            );
                                          let pos = additional_definition
                                            .map((val) => val.label)
                                            .indexOf(definition.label);
                                          additional_definition[pos].value =
                                            definition.value;

                                          formData.grant_additional_info =
                                            JSON.stringify(
                                              additional_definition
                                            );
                                        }
                                          ">
                                      </Dropdown>
                                    </div>
                                    <div v-else class="">
                                      <InputText :ref="'ctrladditional_form_definition' +
                                        index
                                        " v-model="definition.value" :label="definition.label"
                                        :type="definition.datatype" :placeholder="definition.label" class="w-full"
                                        :pattern="validationRegex(definition)" :required="true" min="0" @change="() => {
                                          let additional_definition =
                                            JSON.parse(
                                              formData.grant_additional_info
                                            );
                                          let pos = additional_definition
                                            .map((val) => val.label)
                                            .indexOf(definition.label);
                                          additional_definition[pos].value =
                                            definition.value;

                                          formData.grant_additional_info =
                                            JSON.stringify(
                                              additional_definition
                                            );
                                        }
                                          ">
                                      </InputText>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="formData.third_party_agencies_grant
                              .website_url &&
                              formData.third_party_agencies_grant
                                .has_additional_form_online == 'Yes'
                              " class="col-12 md:col-12 px-2">
                              <Divider align="left" type="dashed" class="my-2 p-divider-bottom">
                                <a style="text-decoration: none" class="btn animate__shakeX text-sm text-orange-500"
                                  target="_blank" :href="formData.third_party_agencies_grant
                                    .website_url
                                    ">{{
                                        $t(
                                          "click_here_to_provide_application_form_data"
                                        )
                                      }}</a>
                                </Divider>
                            </div>
                          </div>
                        </div>
                        <th>
                          <Button class="p-button-text p-button-danger p-button-rounded p-button-sm"
                            v-if="arrFormData.length > minRowCount" @click="removeFormRow(row)" icon="pi pi-times" />
                        </th>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="100" class="text-left">
                          <Button v-if="arrFormData.length < maxRowCount" @click="addFormRow()" icon="pi pi-plus" :label="arrFormData.length < 1
                            ? $t('apply_now')
                            : $t('add_more')
                            " class="p-button-text p-button-success p-button-rounded p-button-sm" />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div v-if="showSubmitButton" class="text-center q-my-md">
                  <Button type="submit" :label="$t('submit')" icon="pi pi-send" :loading="saving" />
                </div>
              </form>
              <slot :submit="submit" :saving="saving"></slot>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "applicantselectedgrantsPage",
  components: {},
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicant_selected_grants",
    },
    maxRowCount: {
      type: Number,
      default: 3,
    },
    minRowCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      arrFormData: [],
    };
  },
  validations() {
    let arrFormData = {
      $each: helpers.forEach({
        third_party_agency_id: { required },
        third_party_agencies_grant_id: { required },
        grant_additional_info: {},
      }),
    };
    return { arrFormData };
  },
  computed: {
    pageTitle: {
      get: function () {
        return this.$t("add_new_grant");
      },
    },
  },
  methods: {
    ...mapActions("applicant_selected_grants", ["saveRecord"]),
    validateForm() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      return isFormValid;
    },
    addFormRow() {
      let newFormData = {
        third_party_agency_id: "",
        third_party_agencies_grant: "",
        third_party_agencies_grant_id: "",
        grant_additional_info: [],
      };
      this.arrFormData.push(newFormData);
    },
    removeFormRow(index) {
      this.arrFormData.splice(index, 1);
    },
    resetForm() {
      this.arrFormData = [
        {
          third_party_agency_id: "",
          third_party_agencies_grant: "",
          third_party_agencies_grant_id: "",
          grant_additional_info: [],
        },
      ];
      this.updateFormData();
    },
    getErrorClass(row, field) {
      let errors = this.v$.arrFormData.$each.$response.$errors[row][field];
      return { "p-invalid": errors.length && this.submitted };
    },
    validationRegex(formField) {
      if (formField.data_type == "Number") {
        return "^[0-9]+$";
      } else if (formField.data_type == "Text") {
        return "^[A-Za-z-' ]+$";
      } else {
        return "^[A-Za-z0-9-', ]+$";
      }
    },
  },
  watch: {},
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>